<template lang="pug">
  div.wrap-item-inbox-card.py12
    div.card-content
      span.name {{content.name}}
      div.f.fm.flex-between
        span.price {{`${content.amount}円`}}
        span(@click="buy").button-buy.px10.py6 購入する
</template>

<style lang="scss" scoped>
.wrap-item-inbox-card {
  background: #fff;
  border-bottom: solid rgba(0, 0, 0, 0.4) 0.5px;
  .card-content {
    width: 93%;
    max-width: 820px;
    margin: 0 auto;
    .name {
      font-size: 16px;
      font-weight: bold;
    }
    .price {
      color: #999;
    }
    .button-buy {
      font-size: 12px;
      border-radius: 3px;
      background: #1867c0;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
  },
  methods: {
    buy () {
      this.$emit('openModalBuyProduct', this.content.id)
    }
  }
}
</script>
