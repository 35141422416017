<template lang="pug">
  div(@click="onCard").wrap-item-inbox-card.py12
    div.card-content
      div.f.fm.flex-between
        span.name {{productName}}
        span.stock {{`(${this.content.stock}/${this.content.num})`}}
      span.date {{boughtDate}}
</template>

<style lang="scss" scoped>
.wrap-item-inbox-card {
  background: #fff;
  border-bottom: solid rgba(0, 0, 0, 0.4) 0.5px;
  .card-content {
    width: 93%;
    max-width: 820px;
    margin: 0 auto;
    span {
      display: block;
    }
    .name {
      font-weight: bold;
      font-size: 16px;
    }
    .date,
    .stock {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    productName () {
      return this.products.filter(e => {
        return e.id === this.content.productId
      })[0].name
    },
    boughtDate () {
      return '購入日：' + format(this.content.created.toDate(), 'yyyy-mm-dd HH:MM')
    }
  },
  async created () {
  },
  methods: {
    onCard () {
      this.$emit('openModalBuyProduct', this.content.id)
    }
  }
}
</script>
