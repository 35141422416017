<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="onFailedAuthentication")
    ClientHeader(:content="headerContent")
    div.wrap-client-product
      ModuleClientProduct(
        v-if="clientId"
        :clientId="clientId"
        @openModalBuyProduct="openModalBuyProduct")
    ClientFooter
    ModalWindow(
      @closeModal="closeModalWindow"
      modalContentId="buyProduct"
      :showModal="showModalWindow"
      :option="option")
</template>

<style lang="scss" scoped>
.wrap-client-product {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import ClientHeader from '@/components/shared/ClientHeader'
import ClientFooter from '@/components/shared/ClientFooter'
import ModalWindow from '@/components/shared/Modal'
import ModuleClientProduct from '@/components/module/ModuleClientProduct'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ClientHeader,
    ClientFooter,
    ModalWindow,
    ModuleClientProduct
  },
  computed: {
    ...mapStateAuth(['uid'])
  },

  data () {
    return {
      headerContent: {
        label: 'チケット'
      },
      showModalWindow: false,
      option: {},
      clientId: null
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/client-sign-in')
    },
    async onLoggedIn () {
      this.clientId = await db.collection('CLIENTS')
        .where('authedClientUid', '==', this.uid)
        .get()
        .then(q => {
          return q.docs[0].id
        })
      this.option.clientId = this.clientId
    },
    openModalBuyProduct (productId) {
      this.option.productId = productId
      this.showModalWindow = true
    },
    closeModalWindow () {
      this.showModalWindow = false
    }
  }
}
</script>
