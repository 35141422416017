<template lang="pug">
  div.wrap-module-client-product
    div.product-header.f
      div(@click="tab = 'products'"
        :class="{'active': tab === 'products'}").tab.f.fh
        span 商品
      div(@click="tab = 'my_items'"
        :class="{'active': tab === 'my_items'}").tab.f.fh
        span 購入済み
    div(v-if="tab === 'products'").product-content
      ItemProductCard(
        v-for="item in products"
        :content="item"
        @openModalBuyProduct="openModalBuyProduct")
    div(v-if="tab === 'my_items'").product-content
      ItemMyItemCard(
        v-if="myItems[0] && products"
        v-for="item in myItems"
        :content="item"
        :products="products")
      div(v-else).no-content.f.fh
        span 購入した商品がありません。
</template>

<style lang="scss" scoped>
.wrap-module-client-product {
  width: 100%;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 48px;
  .product-header {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    background: #fff;
    .tab {
      width: 50%;
      height: 24px;
      border-bottom: solid #999 0.5px;
      span {
        font-size: 12px;
        color: #999;
      }
      &.active {
        border-bottom: solid #1867c0 1px;
        span {
          color: #1867c0;
        }
      }
    }
  }
  .product-content {
    .no-content {
      width: 100%;
      height: calc(100vh - 48px * 2 - 24px);
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import ItemProductCard from '@/components/item/ItemProductCard'
import ItemMyItemCard from '@/components/item/ItemMyItemCard'

export default {
  components: {
    ItemProductCard,
    ItemMyItemCard
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      myItems: null,
      products: null,
      tab: 'products'
    }
  },
  async created () {
    db.collection('CLIENTS')
      .doc(this.clientId)
      .collection('PRODUCTS')
      .onSnapshot(this.getMyItems)
    this.products = await db.collection('PRODUCTS')
      .get()
      .then(q => {
        return q.docs.map(d => {
          var data = d.data()
          data.id = d.id
          return data
        })
      })
  },
  methods: {
    getMyItems (q) {
      this.myItems = q.docs.map(d => {
        var data = d.data()
        data.id = d.id
        return data
      })
    },
    openModalBuyProduct (productId) {
      this.$emit('openModalBuyProduct', productId)
    }
  }
}
</script>
